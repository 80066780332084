import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './components/Home';
import subMenu from './components/subcatgorymenu';
import menu from './components/menu';
import loginpage from './components/loginPage';
import regPage from './components/registraionPage';

import plus from './components/Mathematics/Plus';
import mathsQuizSummary from './components/Mathematics/mathsQuizSummary';

import dictation from './components/English/dictation';
import englishQuizSummary from './components/English/englishQuizSummary';

import bodypart from './components/Science/bodyparts';
import scienceQuizSummary from './components/Science/scienceQuizSummary';

import mathsReport from './components/Reports/mathsReport';
import highscore from './components/Reports/highscore';

import videoalbum from './components/videoplayer/videoalbum'
import videomain from './components/videoplayer/videomainpage'

function App() {
  return (
    <Router>
      <Route path="/" exact component={loginpage} />

 {/* ----------------------------videoooooo album                  -------------------- */}
      <Route path="/video-album" exact component={videoalbum} />
      <Route path="/video-main" exact component={videomain} />


      <Route path="/menu" exact component={menu} />
      <Route path="/submenu" exact component={subMenu} />
      <Route path="/mathsreport" exact component={mathsReport} />
      <Route path="/loginpage" exact component={loginpage} />
      <Route path="/registraionpage" exact component={regPage} />
      
      <Route path="/mathsreport" exact component={mathsReport} />
      <Route path="/highscore" exact component={highscore} />

      <Route path="/maths/plus" exact component={plus} />
      <Route path="/maths/mathsQuizSummary" exact component={mathsQuizSummary} />

      <Route path="/english/dictation" exact component={dictation} />
      <Route path="/english/englishQuizSummary" exact component={englishQuizSummary} />

      
      <Route path="/science/bodypart" exact component={bodypart} />
      <Route path="/science/scienceQuizSummary" exact component={scienceQuizSummary} />

    </Router>
  );
}

export default App;