import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import M from 'materialize-css';
import classnames from 'classnames';
import dictationquestions from './questions/dictation.json';
import isEmpty from '../../utils/is-empty';
import correctNotification from '../../assets/audio/correct-answer.mp3';
import wrongNotification from '../../assets/audio/wrong-answer.mp3';
import buttonSound from '../../assets/audio/button-sound.mp3';
import backgroundmusic from '../../assets/audio/backmusic.mp3';

import background from '../../assets/img/back2.jpg'
import bal from '../../assets/img/bbbb.gif'
import bal2 from '../../assets/img/birdy2.gif'

// import butback2 from '../../assets/img/wddddd.gif'


class Play extends Component {
    constructor (props) {
        super(props);
        this.state = {
            dictationquestions,
            currentQuestion: {},
            nextQuestion: {},
            previousQuestion: {},
            answer: '',
            numberOfQuestions: 0,
            numberOfAnsweredQuestions: 0,
            currentQuestionIndex: 0,
            score: 0,
            correctAnswers: 0,
            wrongAnswers: 0,
            nextButtonDisabled: false,
            previousRandomNumbers: [],
            time: {},
            height: props.height
        };
        this.interval = null;
        this.correctSound = React.createRef();
        this.wrongSound = React.createRef();
        this.buttonSound = React.createRef();
        this.backmusic=React.createRef();

    }

    componentDidMount () {
        this.setState({height: window.innerHeight + 'px'});
        
       this.backmusic.current.play();
        const { dictationquestions, currentQuestion, nextQuestion, previousQuestion } = this.state;
        this.displayQuestions(dictationquestions, currentQuestion, nextQuestion, previousQuestion);
        this.startTimer();
        
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    displayQuestions = (dictationquestions = this.state.dictationquestions, currentQuestion, nextQuestion, previousQuestion) => {
        let { currentQuestionIndex } = this.state;   

      // console.log('type',dictationquestions[currentQuestionIndex].cat=='sub')

        if (!isEmpty(this.state.dictationquestions)) {
            dictationquestions = this.state.dictationquestions;
            currentQuestion = dictationquestions[currentQuestionIndex];
            nextQuestion = dictationquestions[currentQuestionIndex + 1];
            previousQuestion = dictationquestions[currentQuestionIndex - 1];
            const answer = currentQuestion.answer;
            this.setState({
                currentQuestion,
                nextQuestion,
                previousQuestion,
                numberOfQuestions: dictationquestions.length,
                answer,
                previousRandomNumbers: []
            }, () => {
                this.showOptions();
                this.handleDisableButton();
            });
        }     
    };

    handleOptionClick = (e) => {
        if (e.target.innerHTML.toLowerCase() === this.state.answer.toLowerCase()) {
            this.correctTimeout = setTimeout(() => {
                this.correctSound.current.play();
            }, 500);
            this.correctAnswer();
        } else {
            this.wrongTimeout = setTimeout(() => {
                this.wrongSound.current.play();
            }, 500);
            this.wrongAnswer();
        }
    }

    handleNextButtonClick = () => {
        this.playButtonSound();
        if (this.state.nextQuestion !== undefined) {
            this.setState(prevState => ({
                currentQuestionIndex: prevState.currentQuestionIndex + 1
            }), () => {
                this.displayQuestions(this.state.state, this.state.currentQuestion, this.state.nextQuestion, this.state.previousQuestion);
            });
        }
    };

    

    handleQuitButtonClick = () => {
        this.playButtonSound();
        if (window.confirm('Are you sure you want to quit?')) {
            this.props.history.push('/menu');
        }
    };

    handleButtonClick = (e) => {
        switch (e.target.id) {
            case 'next-button':
                this.handleNextButtonClick();
                break;

            case 'quit-button':
                this.handleQuitButtonClick();
                break;

            default:
                break;
        }
        
    };

    playButtonSound = () => {
        this.buttonSound.current.play();
    };

    correctAnswer = () => {
        M.toast({
            html: 'Correct Answer!',
            classes: 'toast-valid',
            displayLength: 1000,
        });
        this.setState(prevState => ({
            score: prevState.score + 1,
            correctAnswers: prevState.correctAnswers + 1,
            currentQuestionIndex: prevState.currentQuestionIndex + 1,
            numberOfAnsweredQuestions: prevState.numberOfAnsweredQuestions + 1
        }), () => {            
            if (this.state.nextQuestion === undefined) {
                this.endGame();
            } else {
                this.displayQuestions(this.state.dictationquestions, this.state.currentQuestion, this.state.nextQuestion, this.state.previousQuestion);
            }
        });
    }

    wrongAnswer = () => {
        
       //navigator.vibrate(1000);
        M.toast({
            html: 'Wrong Answer!',
            classes: 'toast-invalid',
            displayLength: 1000
        });
        this.setState(prevState => ({
            wrongAnswers: prevState.wrongAnswers + 1,
            currentQuestionIndex: prevState.currentQuestionIndex + 1,
            numberOfAnsweredQuestions: prevState.numberOfAnsweredQuestions + 1
        }), () => {
            if (this.state.nextQuestion === undefined) {
                this.endGame();
            } else {
                this.displayQuestions(this.state.dictationquestions, this.state.currentQuestion, this.state.nextQuestion, this.state.previousQuestion);
            }
        });
    }

    showOptions = () => {
        const options = Array.from(document.querySelectorAll('.option'));

        options.forEach(option => {
            option.style.visibility = 'visible';
        });

      
    }

    


    startTimer = () => {
        const countDownTime = Date.now() + 600000;
        this.interval = setInterval(() => {
            const now = new Date();
            const distance = countDownTime - now;

            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(this.interval);
                this.setState({
                    time: {
                        minutes: 0,
                        seconds: 0  
                    }
                }, () => {
                    this.endGame();
                });
            } else {
                this.setState({
                    time: {
                        minutes,
                        seconds,
                        distance
                    }
                });
            }
        }, 1000);
    }

    handleDisableButton = () => {

        if (this.state.nextQuestion === undefined || this.state.currentQuestionIndex + 1 === this.state.numberOfQuestions) {
            this.setState({
                nextButtonDisabled: true
            });
        } else {
            this.setState({
                nextButtonDisabled: false
            });
        }
    }

    endGame = () => {
       // alert('Quiz has eneded!');
        const { state } = this;
        const playerStats = {
            score: state.score,
            numberOfQuestions: state.numberOfQuestions,
            numberOfAnsweredQuestions: state.correctAnswers + state.wrongAnswers,
            correctAnswers: state.correctAnswers,
            wrongAnswers: state.wrongAnswers,
           
        };
        setTimeout(() => {
            this.props.history.push('/english/englishQuizSummary', playerStats);
        }, 1000);
    }

    render () {
        const { 
            currentQuestion, 
            currentQuestionIndex, 
            numberOfQuestions,
            time,
            height ,
           
        } = this.state;
       
        return (
           
            <div>
                <Helmet><title>Maths Quiz Page</title></Helmet>
                <Fragment>
                    <audio ref={this.correctSound} src={correctNotification}></audio>
                    <audio ref={this.wrongSound} src={wrongNotification}></audio>
                    <audio ref={this.buttonSound} src={buttonSound}></audio>
                    <audio ref={this.backmusic} src={backgroundmusic}></audio>
                </Fragment>
                
                <div className="questions" >
                <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover',height:height }} >
                    
                    <div className="timer-container" style={{height:'5%'}} >
                        <p>
                            <span className="left" style={{ float: 'left',fontSize:'4vw',fontWeight:'bolder'}}>{currentQuestionIndex + 1} of {numberOfQuestions}</span>
                            <span 
                            style={{fontSize:20,color:'#212121',fontSize:'4vw',fontWeight:'bolder'}}
                            className={classnames('right valid', {
                                'warning': time.distance <= 5000,
                                'invalid': time.distance < 3000
                            })}>
                                {time.minutes}:{time.seconds}
                            <span  className="mdi mdi-clock-outline mdi-4vw"></span></span>
                        </p>
                    </div>
                    <div style={{height:'6vw'}}>
                        
                    </div>
                 
                 
                   <div className="que" style={{width:'100%',height:'40%',flexDirection:'column'}}>
                    <div style={{width:'15%',height:'100%',float:"left"}}>
                            <img src={bal2} alt='ele'  width='100%' height='100%'/>
                    </div>
                    <div className="que" style={{width:'70%',height:'100%',float:"left"}}>
                        {currentQuestion.img ?
                        <div style={{flexDirection:'row',width:'100%',height:'100%'}}>
                            <div style={{width:'100%',height:'80%'}}><img src={currentQuestion.img} alt='ele' height='100%'/></div>
                            <div style={{width:'100%',height:'10%',fontSize:'4vw',fontWeight:'600',fontFamily:'arial',textShadow:"3px 3px 2px #E1BEE7",color:'#8E24AA',fontFamily:'Monospace'}}>{currentQuestion.question}</div>
                            </div> 
                        :<div style={{width:'100%',height:'100%',fontSize:'4vw',fontWeight:'600',fontFamily:'arial',justifyContent:'center',display: "flex",textShadow:"3px 3px 2px #E1BEE7",color:'#8E24AA',fontFamily:'Monospace'}}>{currentQuestion.question}</div>
                        }
                    </div>
                    <div style={{width:'15%',height:'100%',float:"right"}}>
                        <img src={bal2} alt='ele' width='100%' height='100%'/>
                    </div>
                    </div>
                  
                    
                     

                      <div style={{flexDirection:"row",width:'100%',height:'30%'}}>

                        <div className="options-container" style={{float:"left",height:'100%',width:'50%'}}>
                            <p onClick={this.handleOptionClick} className="option" style={{height:'40%',fontSize:'4vw',display: "flex",justifyContent: "center",alignItems: "center",textShadow:"5px 5px 20px #424242",color:'#F5F5F5'}}>
                           {currentQuestion.optionA} 
                           </p>

                            <p onClick={this.handleOptionClick} className="option" style={{height:'40%',fontSize:'4vw',display: "flex",justifyContent: "center",alignItems: "center",textShadow:"5px 5px 20px #424242",color:'#F5F5F5'}}>
                            {currentQuestion.optionB} 
                            </p>
                        </div>
                        <div className="options-container"  style={{float:"left",height:'100%'}}>
                            <p onClick={this.handleOptionClick} className="option" style={{height:'40%',fontSize:'4vw',display: "flex",justifyContent: "center",alignItems: "center",textShadow:"5px 5px 20px #424242",color:'#F5F5F5'}}>
                            {currentQuestion.optionC} 
                            </p>
                            <p onClick={this.handleOptionClick} className="option" style={{height:'40%',fontSize:'4vw',display: "flex",justifyContent: "center",alignItems: "center",textShadow:"5px 5px 20px #424242",color:'#F5F5F5'}}>
                               
                                {currentQuestion.optionD}
                               
                               
                            </p>
                        </div>
                        
                    </div>

                    <div className="button-container" style={{height:'8%',width:'100%',marginTop:'2vw'}}>
                     
                        <button 
                            className={classnames('', {'disable': this.state.nextButtonDisabled})}
                            id="next-button" 
                            onClick={this.handleButtonClick}
                            style={{width:'25%',height:'100%',fontSize:'2vw',display: "flex",justifyContent: "center",alignItems: "center",fontWeight:'bolder'}}
                            >
                                Next
                            </button>
                        <button style={{width:'25%',height:'100%',fontSize:'2vw',display: "flex",justifyContent: "center",alignItems: "center",fontWeight:'bolder'}} id="quit-button" onClick={this.handleButtonClick}>Quit</button>
                    </div>
                </div>
                </div>
             
            </div>
          
        );
    }
}

export default Play;