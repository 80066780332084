import React, { Component } from 'react';
import CanvasJSReact from './canvasjs.react';
import Axios from 'axios'
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class SplineChart extends Component {

    constructor (props) {
        super(props);
        this.state = {
           date:'',
           score:'',
           allscores: [],
           graphdate:[],
           graphscore:[],
            
           sample:[{ x: 4, y: 100 },
            { x: 5, y: 64 },
            { x: 6, y: 60 },
            { x: 7, y: 10 }]
        }
    }

    componentDidMount () {
       //console.log(this.props.subject)
       console.log('QQQ')
       this.ScoreLoad();
    }

    setUserScore(data) {
        this.setState({ allscores: data });
      }

    ScoreLoad = async ()=>{
        Axios.get('http://localhost:8000/api/scores/Science/Nimesh').then(function (data) {
           this.setUserScore(data.data);
      
            console.log(data.data);
            this.setState({ isLoaded: true, })
            
        }.bind(this))
        .catch(error => console.log(error));
      }
    
    


	render() {
        
        console.log("AAB")
		const options = {
			animationEnabled: true,
			 exportEnabled: true,
            theme: "light1", // "light1", "dark1", "dark2"
			title:{
				text: this.props.month+" Week "+this.props.week+" - "+ this.props.subject
            },
			axisY: {
				title:"Score Rate",
				includeZero: false,
                suffix: "%",
                interval: 20,
               
               
			},
			axisX: {
				title: "Day of Week",
				
                interval: 1,
                prefix:''
			},
			data: [{
				type: "line",
                toolTipContent: "day {x}: {y}%",
                
              
				dataPoints:this.state.sample
			}]
        }
       
		return (
		<div >
            {/* {this.state.scores.map((item,index) => (
                // {index ? : }
                console.log(item.score)
            ))} */}

            <CanvasJSChart options = {options}/>
			
		</div>
		);
	}
}

export default SplineChart;                           