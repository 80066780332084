import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import background from '../../assets/img/backsky2.jpg'
import sad from '../../assets/img/sad.png'
import great from '../../assets/img/great.png'
import luck from '../../assets/img/luck.png'
import more from '../../assets/img/more.png'
import dance from '../../assets/img/kidsss.png'
import Axios from 'axios';
class QuizSummary extends Component {
    constructor (props) {
        super(props);
        this.state = {
            score: 0,
            numberOfQuestions: 0,
            numberOfAnsweredQuestions: 0,
            correctAnswers: 0,
            wrongAnswers: 0,
            height: props.height
            // hintsUsed: 0,
            // fiftyFiftyUsed: 0
        };
    }

    componentDidMount () {
        const { state } = this.props.location;
        var day = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hours = new Date().getHours(); //Current Hours
        var min = new Date().getMinutes(); //Current Minutes
       
        var date=year+"-"+month+"-"+day+" "+hours+":"+min
        if (state) {
            this.setState({
                score: (state.score / state.numberOfQuestions) * 100,
                numberOfQuestions: state.numberOfQuestions,
                numberOfAnsweredQuestions: state.numberOfAnsweredQuestions,
                correctAnswers: state.correctAnswers,
                wrongAnswers: state.wrongAnswers,
                height: window.innerHeight + 'px'
                // hintsUsed: state.hintsUsed,
                // fiftyFiftyUsed: state.fiftyFiftyUsed
            });
            Axios({
                method: 'post',
                url: 'http://localhost:8000/api/maths-score',
                data: {
                    email:"tesh@gmai.com",
                    username:'Teshan',
                    score:(state.score / state.numberOfQuestions) * 100,
                    dateandtime:date,
                  
                }
                })
                .then(function (response) {
              
                }) 
        }
    }

    render () {
        const { state } = this.props.location;
        let stats, remark;
        const userScore = this.state.score;
        const height = this.state;

        if (userScore <= 30 ) {
            remark = <div style={{width:'100%'}}><img src={sad} alt='sad' height='60vw'/> <text style={{display: "flex",justifyContent: "center",alignItems: "center"}}>You need more practice!</text></div>;
        } else if (userScore > 30 && userScore <= 50) {
            remark = <div style={{width:'100%'}}><img src={luck} alt='luck' height='60vw'/> <text style={{display: "flex",justifyContent: "center",alignItems: "center"}}>Better luck next time!</text></div>;
        } else if (userScore <= 70 && userScore > 50) {
            remark = <div style={{width:'100%'}}><img src={more} alt='more' height='60vw'/> <text style={{display: "flex",justifyContent: "center",alignItems: "center"}}>You can do better!</text></div>;
        } else if (userScore >= 71 && userScore <= 84) {
            remark = <div style={{width:'100%'}}><img src={more} alt='great' height='60vw'/> <text style={{display: "flex",justifyContent: "center",alignItems: "center"}}>You did great!</text></div>;
        } else {
            remark = <div style={{width:'100%'}}><img src={great} alt='good' height='60vw'/> <text style={{display: "flex",justifyContent: "center",alignItems: "center"}}>You're an absolute genius!</text></div>;
        }

        if (state !== undefined) {
            stats = (
                <div style={{height:'100%'}}>
                    <div style={{ textAlign: 'center',}} >
                            <img src={dance} alt='ele' height='150vw'/>
                            <p style={{fontSize:'6vw',fontWeight:'bolder',color:'#3949AB',marginTop:'-5vw'}}>Quiz has ended</p>
                            <ul style={{width:'100%',display: "flex",marginBottom:'1vw'}}>
                                <li style={{display: "flex",justifyContent: "center",alignItems: "center",fontWeight:'bolder',width:'100%'}}>
                                    <button style={{background: "linear-gradient(#81D4FA,#F8BBD0,#F48FB1,#EC407A)",width:'90%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                                    ,borderWidth:'0.5vw',borderColor:'#2196F3'}}>
                                        <Link to ="/maths/plus" style={{color:'#880E4F'}}>Play Again</Link>
                                    
                                    </button>
                                   
                                </li>
                                <li  style={{display: "flex",justifyContent: "center",alignItems: "center",fontWeight:'bolder',width:'100%'}}>
                                <button style={{background: "linear-gradient(#81D4FA,#F8BBD0,#F48FB1,#EC407A)",width:'90%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                                    ,borderWidth:'0.5vw',borderColor:'#2196F3'}}>
                                        <Link to ="/menu" style={{color:'#880E4F'}}>Back to Home</Link>
                                    </button>
                                    
                                </li>
                            </ul>
                            
                       
                    </div>
                    
                   
                    <div className="container stats" style={{fontWeight:'bold',backgroundColor:'#EDE7F6',}}>
                       
                        <h2 style={{fontFamily:'Arial'}}>Your Score: {this.state.score.toFixed(0)}&#37;</h2>
                        <h4 style={{fontSize:'3vw',color:'#1A237E'}}>{remark}</h4>

                        <span className="stat left">Total number of questions: </span>
                        <span className="right" style={{color:'#AD1457'}}>{this.state.numberOfQuestions}</span><br />

                        <span className="stat left">Number of attempted questions: </span>
                        <span className="right" style={{color:'#AD1457'}}>{this.state.numberOfAnsweredQuestions}</span><br />

                        <span className="stat left">Number of Correct Answers: </span>
                        <span className="right" style={{color:'#AD1457'}}>{this.state.correctAnswers}</span> <br />

                        <span className="stat left">Number of Wrong Answers: </span>
                        <span className="right" style={{color:'#AD1457'}}>{this.state.wrongAnswers}</span><br />

                        
                    </div>
                   
                </div>
            );
        } else {
            stats = (
                <section>
                    <h1 className="no-stats" style={{justifyContent:'center'}}>No Statistics Available</h1>
                    <ul >
                    <li style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <Link to ="/maths/plus">Take a Quiz</Link>
                        </li>
                        <li  style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <Link to ="/menu">Back to Home</Link>
                        </li>
                    </ul>
                </section>
            );
        }
        return (
            <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover',height:height }}>
                
                <div className="quiz-summary" style={{height:"100%"}} >
                    {stats}
                </div>
            </div>
        );
    }
}

export default QuizSummary;