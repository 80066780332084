import React, { Component, Fragment } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Row,
    Col,
    Modal,
    CardTitle,
  } from "reactstrap";
  
  

import { Link } from 'react-router-dom';
import background from '../assets/img/skyy2.jpg'
import brdcopy from '../assets/img/brdcopy.png'
import kids from '../assets/img/kidsss.png'
import uni from '../assets/img/abcd.png'
import bck from '../assets/img/kkbacground.png'
import btn from '../assets/img/btn.png'
// import dance from '../assets/img/kidsss.png'

class loginPage extends Component{
    constructor (props) {
        super(props);
        this.state = {
            height: props.height
        }
    }

    componentDidMount () {
        this.setState({height: window.innerHeight+'px'});
    }

    render(){
        const { 
            height ,
        } = this.state;
      
console.log(height)
        return(
                <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover' ,height:height,
                justifyContent: "center",alignItems: "center",flexDirection:'column'}} >
                  
                  <div style={{width:'100%',textAlign:'center',height:'55%',justifyContent: "center",alignItems: "center",display:'flex',flexDirection:'column'}}>
                  
                        <div style={{width:'40%',borderRadius:'10px',marginTop:'5%'}}>
                            <input
                                    placeholder='E-mail'
                                    type="text"
                                    style={{color:'#F5F5F5',textAlign:'center',borderColor:'#FAFAFA',background: "linear-gradient(#0D47A1,#1565C0,#0D47A1)",fontFamily:'Monospace'
                                ,borderTopLeftRadius:'2vw',borderTopRightRadius:'2vw',borderBottomRightRadius:'2vw',borderBottomLeftRadius:'2vw',borderColor:'#1A237E',borderWidth:'2px',height:'7vw',fontSize:'3vw'}}
                                    
                                /> 
                        </div> 
                        <div style={{width:'40%'}}>
                        <Link to="/video-main">
                            <button style={{background: "radial-gradient(#5C6BC0,#283593,#212121)",width:'60%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'6vw',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                        ,borderWidth:'0.5vw',borderColor:'#BBDEFB'}}>
                                LOGIN
                            </button>
                            </Link>
                            
                            <Link className="play-button" to="/registraionpage">
                                <button style={{marginTop:'5%',background: "linear-gradient(#FFF176,#FFEB3B,#F9A825)",width:'100%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'4vw',fontFamily:'Monospace',color:'#3E2723',fontSize:'2vw'
                                    ,borderWidth:'0.5vw',borderColor:'#3E2723'}}>
                                        Register
                                
                                </button>
                            </Link>
                        </div>  

                  </div>
                  <div style={{height:'45%',justifyContent: "center",alignItems: "center",display:'flex',backgroundImage:`url(${bck})`,backgroundSize:"cover"}}>
                        <img src={uni} alt='ele' height='100%'/>
                  </div>
                  
                  
                </div>
          
      
        )
    }
}

export default loginPage;