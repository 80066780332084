import React, { Component, Fragment } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Row,
    Col,
    Modal,
    CardTitle,
  } from "reactstrap";
  
  
  import backbttn from '../assets/img/backbttn.png'
import { Link } from 'react-router-dom';
import background from '../assets/img/tback.jpg'
import brdcopy from '../assets/img/brdcopy.png'
import kids from '../assets/img/kidsss.png'
import bck from '../assets/img/kkbacground.png'
import btn from '../assets/img/btn.png'
import Axios from 'axios';
// import dance from '../assets/img/kidsss.png'

class rigistraionPage extends Component{
    constructor (props) {
        super(props);
        this.state = {
            height: props.height,
            email:'',
            username:''
        }
    }

    setUsername=(e) => {
		this.setState({username:e.target.value});
    }
    
    setEmail=(e) => {
        this.setState({email:e.target.value});
    }

    
    componentDidMount () {
        this.setState({height: window.innerHeight+'px'});
    }




    render(){
        const { 
            height ,username,email
        } = this.state;
console.log(height)
        return(
                <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover' ,height:height,
                justifyContent: "center",alignItems: "center",flexDirection:'column'}} >
                <div style={{backgroundColor:'',height:'20%',width:"10%"}}>
                    <Link to ="/">
                    <img src={backbttn} alt='sad' height='90%'style={{margin:'5%'}}/> 
                    </Link>
                </div>
                   <div style={{height:'15%',justifyContent: "center",alignItems: "center",display:'flex',paddingTop:'5%'}}>                       
                  
                        {/* <img src="https://raw.githubusercontent.com/rnimesh/WchatPicture/master/ppic.png" alt='ele' height='100%'/> */}
                  </div>
                  
                  <div style={{width:'100%',textAlign:'center',height:'55%',justifyContent: "center",alignItems: "center",display:'flex',flexDirection:'column'}}>
                  
                        <div style={{width:'40%',borderRadius:'10px'}}>
                                
                      
                                 <input
                                    placeholder='user name'
                                    onChange={this.setUsername}
                                    type="text"
                                    maxLength="6"
                                    style={{color:'#F5F5F5',textAlign:'center',borderColor:'#3E2723',background: "linear-gradient(#3E2723,#6D4C41,#3E2723)",fontFamily:'Monospace'
                                ,borderTopLeftRadius:'2vw',borderTopRightRadius:'2vw',borderBottomRightRadius:'2vw',borderBottomLeftRadius:'2vw',borderWidth:'2px',height:'7vw',fontSize:'3vw'}}
                        
                                /> 
                                <input
                                    placeholder='E-mail'
                                    onChange={this.setEmail}
                                    type="text"
                                    style={{color:'#F5F5F5',textAlign:'center',borderColor:'#3E2723',background: "linear-gradient(#3E2723,#6D4C41,#3E2723)",fontFamily:'Monospace'
                                ,borderTopLeftRadius:'2vw',borderTopRightRadius:'2vw',borderBottomRightRadius:'2vw',borderBottomLeftRadius:'2vw',borderWidth:'2px',height:'7vw',fontSize:'3vw'}}
                                    
                                /> 
                               
                        </div> 
                        <div style={{width:'40%',marginTop:'2%'}}>
                            <button style={{background: "radial-gradient(#A1887F,#6D4C41,#212121)",width:'60%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'6vw',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'3vw'
                        ,borderWidth:'0.5vw',borderColor:'#795548'}}
                        onClick={()=>{
                            
                            //email.match(/^[a-zA-Z ]*$/)
                            if(!username=="" && !email==""){
                                
                                if(email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
                                   
                                    Axios.get('http://localhost:8000/api/user/'+email).then(function (data) {

                                        if(data.data=='notavailable'){
                                            Axios({
                                            method: 'post',
                                            url: 'http://localhost:8000/api/register-user',
                                            data: {
                                                username:username,
                                                email:email,
                                                imageone:"https://raw.githubusercontent.com/rnimesh/WchatPicture/master/ppic.png"
                                                
                                            }
                                            }).then(function (responce) {
                                                console.log(responce)
                                                alert('Registered successfully');
                                             }) 
                                        }
                                        else{
                                            alert('Sorry!!.. Already have an account from this email address');
                                        }
                                       
                                        })
                                }
                                else{
                                    alert('Invalid Email format');
                                }
                            
                            }else{
                                alert('Field cannot empty');
                            }

                            

                        }}
                        >
                                REGISTER
                            </button>
                        </div>  

                  </div>
                 
                  
                </div>
          
      
        )
    }
}

export default rigistraionPage;