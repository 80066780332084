import React, { Component } from "react";
import Slider from "react-slick";
import { Card, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import Scrollbar from "react-scrollbars-custom";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import headname from '../../assets/img/galname.png'
import background from '../../assets/img/unisky.jpg'
import { Link } from 'react-router-dom';
import videoset from './myvideos.json';

export default class Fade extends Component {
  constructor (props) {
    super(props);
    this.state = {
        videoset,
        newcovers:[],
        allcovers:[],
        random:0,
        random2:0,
        random3:0
      
    };
   
}

componentDidMount(){
  console.log(this.state.newcovers)

   


}


    render() {

      const { newcovers } = this.state;
   
      const settings = {
        // dots: false,
        // fade: true,
        // infinite: true,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // autoplay: true,
        // speed: 3000,
        // autoplaySpeed: 3000,

        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
          arrows:false
    

      };

//new collection
      {videoset.map(vset =>(
        (
         
          vset.version=='new' ? 
          (
            this.state.newcovers.push(vset)
    
          ):
            null
          )
        ))}   
 
 
  


        
      return (
        <div  style={{width:'100%',height:'100vh' ,backgroundColor:"#ffebee"}}>
       
        <div style={{width:'100%',height:'30%',backgroundColor:"#ffebee"}}>
                         
            <div style={{width:'100%',height:'100%',backgroundImage:`url(${background})`,backgroundSize:'cover',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                {/* <text style={{fontSize:'8vh',fontWeight:'bolder'}}>MOVIE WORLD</text> */}
                <Link 
                                    to={`/`} 
                                    style={{width:'100%',display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <button style={{background: "linear-gradient(#81D4FA,#F8BBD0,#F48FB1,#EC407A)",width:'50%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                            ,borderWidth:'0.5vw',borderColor:'#2196F3',color:'#880E4F'}}>
                              Back
                            </button>
                            </Link>
                <img src={headname} height='50%' />
                   
            </div>
            
        </div>

        <div style={{width:'100%',height:'30%',backgroundImage:`url(${background})`,backgroundSize:'cover'}}>
            
            <Slider {...settings} >
              {newcovers.map(newset =>(  
                <div  key={this.state.random3++}>
                    {/* <text  style={{display: 'flex',  justifyContent:'center', alignItems:'center',backgroundColor:"#FFFAFA",fontWeight:'bolder',fontFamily:'arial'}} >STORY 1</text> */}
                    <div style={{width:'100%',height:'100%',display: 'flex',  justifyContent:'center', alignItems:'center',backgroundColor:"#FFFAFA"}}>
                        
                        <img src={newset.longcvr} height='120vh'   width='100%'
                        
                        />
                    </div>
                
                </div>
                ))}
               
            </Slider>

        </div>

        <div style={{width:'100%',height:'10vh',backgroundColor:'#ffebee'}}></div>
        <div style={{width:'100%',height:'60%',backgroundColor:'#ffebee',marginTop:30}}>
           
            <div style={{width:'100%',height:'8vh',backgroundColor:'#ffebee'}}>
               <text style={{textAlign:'left',width:'90%',fontSize:'6vh',fontWeight:'bolder',color:'#B22222',fontFamily:'arial',marginLeft:'2%'}}>New Releases</text>
               
            
            </div>
              <div style={{width:'100%',height:'70%',backgroundColor:'#ffebee'}}>
                <Scrollbar  disableTracksWidthCompensation>

                {newcovers.map(newset =>(  
                  <Card style={{height:'20vh',width:'16%',margin:'2%',float:'left'}} key={this.state.random2++}
                  onClick={()=>{
                    const videourl=newset.video
                    this.props.history.push('/video-album',videourl);
                    
                  }}
                  >
                      <img src={newset.coverpic} height='100%' width='100%'/>
                   
                  </Card>
                    ))}
                </Scrollbar>   

              <div style={{width:'100%',height:'10%',backgroundColor:'#ffebee'}}></div>

            </div>
            
        </div>

        <div style={{width:'100%',height:'80%',backgroundColor:'#ffebee'}}>
           
            <div style={{width:'100%',height:'10%',backgroundColor:'#ffebee'}}>
               <text style={{textAlign:'left',width:'90%',fontSize:'6vh',fontWeight:'bolder',color:'#2980b9',fontFamily:'arial',marginLeft:'2%'}}>Story Collection</text>
            </div>
            <div style={{width:'100%',height:'80%',backgroundColor:'#ffebee'}}>
            <Scrollbar  disableTracksWidthCompensation>

            {videoset.map(vset =>(    
              <Card style={{height:'20vh',width:'16%',margin:'2%',float:'left'}} key={this.state.random++}
                onClick={()=>{
                  const videourl=vset.video
                  this.props.history.push('/video-album',videourl);
                  
                }}
              >
             
                <img src={vset.coverpic} height='100%' width='100%'/>
               
              </Card>
            ))}  
                   

                   
                </Scrollbar>   

            </div>
            
        </div>
        
      </div>
      );
    }
  }