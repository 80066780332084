import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import background from '../assets/img/bacc.jpg'
import dance from '../assets/img/kidsss.png'
import logoutbtn from '../assets/img/logoutbtn.png'
class subMenu extends Component{
    constructor (props) {
        super(props);
        this.state = {
            height: props.height
        }
    }

    componentDidMount () {
        this.setState({height: window.innerHeight + 'px'});
    }
    render(){
        const { 
            height ,
        } = this.state;
        return(
           

                 <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover' ,height:height}} >
                   
                        <div style={{ textAlign: 'center',height:'40%',width:'100%'}}>
                        <div style={{width:'100%',height:"100%",backgroundColor:'',display: "flex",justifyContent: "center"}}>
                        <div style={{width:'15%',height:"40%",backgroundColor:''}}>
                               
                        </div>
                        <div style={{width:'70%',height:"100%",backgroundColor:''}}>
                            {/* <img src={dance} alt='ele' height='100%' /> */}
                        </div>
                        <div style={{width:'15%',height:"45%",textAlign:'right'}}>
                            
                            <Link onClick={()=>{
                                if (window.confirm('Are you sure you want to log out?')) {
                                    this.props.history.push('/');
                                }
                            }}>
                                <img src={logoutbtn} alt='logout' height='90%'style={{margin:'5%'}}/> 
                            </Link>
                        </div>
                            
                        </div>
                     
                        
                        </div>
                        <div  style={{ textAlign: 'center',height:'30%',width:'100%',
                        display: "flex",justifyContent: "center"}}>
                            <div style={{width:'30%',height:"90%",backgroundColor:''}}>
                                <button style={{background: "linear-gradient(#FFF176,#FFEB3B,#F9A825)",width:'80%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'50%',fontFamily:'Monospace',color:'#BF360C',fontSize:'4vw'
                                ,borderWidth:'0.5vw',borderColor:'#3E2723',marginTop:'4vw'}}>
                                   <Link className="play-button" to="/maths/plus" style={{color:'#3E2723'}}>Maths</Link>
                                </button>
                            </div>
                            <div style={{width:'30%',height:"90%",backgroundColor:''}}>
                                <button style={{background: "linear-gradient(#90CAF9,#42A5F5,#039BE5)",width:'80%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'50%',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                                    ,borderWidth:'0.5vw',borderColor:'#1A237E',marginTop:'4vw'}}>
                                   <Link className="play-button" to="/science/bodypart" style={{color:'#1A237E'}}>Science</Link>
                                </button>
                            </div>
                            <div style={{width:'30%',height:"90%",backgroundColor:''}}>
                                <button style={{background: "linear-gradient(#AED581,#8BC34A,#689F38)",width:'80%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'50%',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                                ,borderWidth:'0.5vw',borderColor:'#1B5E20',marginTop:'4vw'}}>
                                   <Link className="play-button" to="/english/dictation" style={{color:'#1B5E20'}}>English</Link>
                                </button>
                            </div>
                        </div>
                        <div  style={{ textAlign: 'center',height:'30%',width:'100%',
                        display: "flex",justifyContent: "center"}}>
                            <div style={{width:'50%',height:"90%",backgroundColor:''}}>
                            <button style={{background: "radial-gradient(#3E2723,#6D4C41,#3E2723)",width:'100%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',height:'50%',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                                ,borderWidth:'0.5vw',borderColor:'#3E2723'}}>
                                   <Link className="play-button" to="/highscore" style={{color:'#D7CCC8'}}>High Score Board</Link>
                                </button>
                            </div>
                           
                        </div>
                        
                   
                </div>
      
        )
    }
}

export default subMenu;