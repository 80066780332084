import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import report from '../../assets/img/report.png'
import ZingChart from 'zingchart-react';

import Report from './report'

class subMenu extends Component{
    constructor (props) {
        super(props);
        this.state = {
            height: props.height,
           
        }
    }

    componentDidMount () {
        this.setState({height: window.innerHeight + 'px'});
    }
    render(){
        const { 
            height ,
        } = this.state;
        return(
           

                <div style={{height:height}}>
                    
               
            
                    <Report subject="Maths" month="January" week="1"/>
                   
                
                    
                </div>
          
      
        )
    }
}

export default subMenu;