import React, { Component, Fragment } from 'react';
import { Link, Router, Route, Switch, Redirect } from 'react-router-dom';
import ReactDOM from "react-dom";
import { Card, Button, CardTitle, CardText, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import Scrollbar from "react-scrollbars-custom";
import { Player, ControlBar,BigPlayButton  } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"
import playbtn from '../../assets/img/playy.png'
import paubtn from '../../assets/img/pausee.png'
import stp from '../../assets/img/stopp.png'

import videoset from './myvideos.json';

const sources = {
    sintelTrailer: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
    bunnyTrailer: 'http://media.w3.org/2010/05/bunny/trailer.mp4',
    bunnyMovie: 'http://media.w3.org/2010/05/bunny/movie.mp4',
    test: 'http://media.w3.org/2010/05/video/movie_300.webm'
  };

class vidalbum extends Component{
    constructor(props, context) {
        super(props, context);
    
        this.state = {
          source: this.props.location.state,
          videoset,
          random:0,
          random1:0
        };

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.load = this.load.bind(this);
        this.stop= this.stop.bind(this);
       
      }

      
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state
    });
  }
      componentDidMount() {
        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));

        console.log(this.props.location.state,"+++++++++++++++")
      }
    
      play() {
        this.player.play();
       
      }
    
      pause() {
        this.player.pause();
       
      }

      load() {
        this.player.load();
        
      }

      stop() {
        this.player.load();
        this.player.pause();
      }
    

      changeSource(url) {
        return () => {
          this.setState({
            source:url 
          });
          this.player.load();
         
         
        };
      }

      
    render(){
       
        return(
           <div style={{width:'100%',height:'100vh',backgroundColor:"green"}}>
            <div style={{width:'60%',float:'left',height:'100vh',backgroundColor:'black'}}>
           <div style={{width:'100%',height:'70%'}} >
            <Player
                    ref={player => {
                        this.player = player;
                    }}
                    autoPlay
                    >
                    <BigPlayButton position="center"/>
                    <source src={this.state.source} />
                    <ControlBar autoHide={false} />
                </Player>

           </div>
           <div style={{width:'100%',height:'30%', display: 'flex',  justifyContent:'center', alignItems:'center'}} >
                <div style={{width:'2%'}}></div>
                <img src={playbtn}  width='60vh' height="60vh" onClick={this.play}/>
                <div style={{width:'2%'}}></div>
                <img src={paubtn}  width='60vh' height="60vh" onClick={this.pause} />  
                <div style={{width:'2%'}}></div>
                <img src={stp}  width='60vh' height="60vh" onClick={this.stop} />  
                <div style={{width:'2%'}}></div>
                   
           </div>
            
           
            </div>
            <div style={{width:'40%',backgroundColor:'white',float:'left',height:'100%'}}>
                <div style={{width:'100%',backgroundColor:'white',height:'13%',marginTop:'2%'}}>
                    <ul style={{width:'100%',display: "flex"}}>
                        <li style={{display: "flex",justifyContent: "center",alignItems: "center",fontWeight:'bolder',width:'100%'}}>
                        <Link 
                                    to={`/video-main`} 
                                    style={{width:'100%',display: "flex",justifyContent: "center",alignItems: "center"}}>
                            <button style={{background: "linear-gradient(#81D4FA,#F8BBD0,#F48FB1,#EC407A)",width:'90%',borderRadius:'15px',letterSpacing:2,fontWeight:'bolder',fontFamily:'Monospace',color:'#F5F5F5',fontSize:'4vw'
                            ,borderWidth:'0.5vw',borderColor:'#2196F3',color:'#880E4F'}}>
                              Menu
                            </button>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div style={{width:'100%',backgroundColor:'white',height:'85%'}}>
                    <Scrollbar  disableTracksWidthCompensation>
                    {videoset.map(vset =>(    
                        <Card style={{height:'20vh',width:'29%',margin:'2%',float:'left'}} key={this.state.random1++}>

                        <img src={vset.coverpic}
                        height='100%' width='100%'
                        onClick={this.changeSource(vset.video)}
                        />
                           
                        </Card>
                    ))}  
                        
                </Scrollbar>
                </div>
           
            </div>

              
              
           </div>
        )
    }

}

export default vidalbum;