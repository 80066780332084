import React, { Component, Fragment } from 'react';
import sad from '../../assets/img/kidd.png'
import backbttn from '../../assets/img/backbttn.png'
import { Link } from 'react-router-dom';
import winner from '../../assets/img/winner2.png'
import background from '../../assets/img/backimage.jpg'
import mathsBoard from '../../assets/img/mathsBoard.png'
import scienceBoard from '../../assets/img/scienceBoard.png'
import englishBoard from '../../assets/img/englishBoard.png'
class highscore extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
          englishallscore:[],
          scienceallscore: [],
          mathsallscore: [],

          scienceScore:[],
          mathsScore:[],
          englishScore:[],

          scienceScoreName:[],
          mathsScoreName:[],
          englishScoreName:[],




          height: props.height,
          width: props.width
        };
      }
      
      
      componentDidMount(){
       
        this.scienceAllScore();
        this.mathsAllScore();
        this.englishAllScore();
        this.setState({height: window.innerHeight + 'px'});
        this.setState({width: window.innerWidth + 'px'});
      }
    
      scienceAllScore=()=>{
        
        fetch('http://localhost:8000/api/all-science')
          .then(res => res.json())
          .then(json=>{
            this.setState({
              scienceallscore:json,
            })
            
          });
      }

      mathsAllScore=()=>{
        
        fetch('http://localhost:8000/api/all-maths')
          .then(res => res.json())
          .then(json=>{
            this.setState({
              mathsallscore:json,
            })
            
          });
      }
      englishAllScore=()=>{
        
        fetch('http://localhost:8000/api/all-english')
          .then(res => res.json())
          .then(json=>{
            this.setState({
              englishallscore:json,
            })
            
          });
      }

    render(){
        const {scienceallscore,height,mathsallscore,englishallscore,width}=this.state;

        {scienceallscore.map(sciencescore =>(
            this.state.scienceScore.push(sciencescore.score+'%'),
            this.state.scienceScoreName.push(sciencescore.username)
         ))}

         {mathsallscore.map(mathsscore =>(
          this.state.mathsScore.push(mathsscore.score+'%'),
          this.state.mathsScoreName.push(mathsscore.username)
          ))}


        {englishallscore.map(engscore =>(
          this.state.englishScore.push(engscore.score+'%'),
          this.state.englishScoreName.push(engscore.username)
        ))}
       
      return(
          
        
        <div style={{backgroundImage:`url(${background})`,backgroundSize:'cover',height:height,width:width }}>
            
            <div style={{width:'100%',height:'21vw'}}>
                {/* <img src={sad} alt='sad'  width='50%'/>  */}
                <div style={{backgroundColor:'',height:'40%',width:"10%"}}>
                  <Link to ="/menu">
                  <img src={backbttn} alt='sad' height='90%'style={{margin:'5%'}}/> 
                  </Link>
                </div>
                
            </div>

            <div  style={{width:'100%',display: "flex",justifyContent: "center",alignItems: "center",height:'25vw'}}>

              <div style={{marginRight:'2%',marginLeft:'2%',width:'18%',backgroundImage:`url(${mathsBoard})`,
              backgroundSize:'contain',height:'100%',display: "flex",justifyContent: "center",
              alignItems: "center",flexDirection:'column',fontFamily:'Impact',
              fontWeight:'bold',color:'#212121',letterSpacing:2,textShadow:"1px 1px 10px #F5F5F5"
            }}>
                <img src={winner} alt='winner'  width='30vw' style={{paddingTop:'5px'}}/> 
                <p style={{backgroundColor:'',fontSize:'3.5vw',textAlign:'center',width:'80%'}}>{this.state.mathsScoreName[0]}</p>
                <p style={{backgroundColor:'',fontSize:'3.5vw'}}>{this.state.mathsScore[0]}</p>    
              </div>

              <div style={{marginRight:'2%',marginLeft:'2%',width:'18%',backgroundImage:`url(${scienceBoard})`,backgroundSize:'contain',height:'100%'
              ,display: "flex",justifyContent: "center",alignItems: "center",flexDirection:'column',fontFamily:'Impact',
              fontWeight:'bold',color:'#212121',letterSpacing:2,textShadow:"1px 1px 10px #F5F5F5"}}>
                <img src={winner} alt='winner' width='30vw' style={{paddingTop:'5px'}}/> 
                <p style={{backgroundColor:'',fontSize:'3.5vw',textAlign:'center',width:'80%'}}>{this.state.scienceScoreName[0]}</p>
                <p style={{backgroundColor:'',fontSize:'3.5vw'}}>{this.state.scienceScore[0]}</p>
              </div>


              <div style={{marginRight:'2%',marginLeft:'2%',width:'18%',backgroundImage:`url(${englishBoard})`,
              backgroundSize:'contain',height:'100%',display: "flex",justifyContent: "center",alignItems: "center"
              ,flexDirection:'column',fontFamily:'Impact',fontWeight:'bold',color:'#212121',letterSpacing:2,
              textShadow:"1px 1px 10px #F5F5F5"}}>
                <img src={winner} alt='winner' width='30vw' style={{paddingTop:'5px'}}/> 
                <p style={{backgroundColor:'',fontSize:'3.5vw',textAlign:'center',width:'80%'}}>{this.state.englishScoreName[0]}</p>
                <p style={{backgroundColor:'',fontSize:'3.5vw'}}>{this.state.englishScore[0]}</p>
              </div>
                
            </div>
           
        </div>
      )
    }
}

export default highscore;